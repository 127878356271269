import { ArrowForwardIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  Link,
  Skeleton,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import NumericalInput from 'components/NumericalInput'
import WarningOutlined from 'components/WarningOutlined'
import Web3Button from 'components/Web3Button'
import useGetAllLBPairs from 'hooks/pool/v2/useGetAllLBPairs'
import useChainId from 'hooks/useChainId'
import useCreateLBPair from 'hooks/useCreateLBPair'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { WarningIcon } from 'theme/icons'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import {
  CREATE_POOL_BIN_STEPS,
  CREATE_POOL_QUOTE_TOKENS_PER_CHAIN
} from './constants'
import CreatePoolTokenSection from './CreatePoolTokenSection'

const CreatePoolV2 = () => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const [currencyX, setCurrencyX] = useState<Currency | undefined>()
  const [currencyY, setCurrencyY] = useState<Currency | undefined>()
  const [selectedBinStep, setBinStep] = useState<number>(100)
  const [activePrice, setActivePrice] = useState<string>('')

  const quoteTokens = CREATE_POOL_QUOTE_TOKENS_PER_CHAIN[chainId]
  const binSteps = CREATE_POOL_BIN_STEPS

  const wrappedCurrencyX = wrappedCurrency(currencyX, chainId)
  const wrappedCurrencyY = wrappedCurrency(currencyY, chainId)

  // check if the pool already exists
  const { data: existingLbPairs, isLoading: isCheckingExistingLBPair } =
    useGetAllLBPairs({
      tokenXAddress: wrappedCurrencyX?.address,
      tokenYAddress: wrappedCurrencyY?.address
    })
  const isPoolAlreadyExisting = useMemo(
    () =>
      existingLbPairs &&
      existingLbPairs.find((lbPair) => lbPair.binStep === selectedBinStep),
    [existingLbPairs, selectedBinStep]
  )

  // create pool
  const {
    createLBPair,
    isCreatingLBPair,
    isLBPairCreated,
    prepareConfigError,
    resetCreateLBPair
  } = useCreateLBPair({
    activePrice: parseFloat(activePrice),
    binStep: selectedBinStep,
    enabled: !isPoolAlreadyExisting && !isCheckingExistingLBPair,
    tokenX: wrappedCurrencyX,
    tokenY: wrappedCurrencyY
  })

  // get prices
  const { data: tokenUsdPrices, isLoading: isLoadingPricesUsd } =
    useGetTokensUsdPrice({
      tokenAddresses:
        wrappedCurrencyX && wrappedCurrencyY
          ? [wrappedCurrencyX.address, wrappedCurrencyY.address]
          : []
    })
  const baseTokenUsdPrice =
    wrappedCurrencyX && tokenUsdPrices?.[wrappedCurrencyX.address.toLowerCase()]
  const quoteTokenUsdPrice =
    wrappedCurrencyY && tokenUsdPrices?.[wrappedCurrencyY.address.toLowerCase()]
  const quoteAmountUsd =
    quoteTokenUsdPrice && activePrice
      ? quoteTokenUsdPrice * parseFloat(activePrice)
      : 0

  const price =
    baseTokenUsdPrice && quoteTokenUsdPrice
      ? baseTokenUsdPrice / quoteTokenUsdPrice
      : undefined
  const fmtCurrentPrice = price ? formattedNum(price, { places: 8 }) : null

  const diffPercent = baseTokenUsdPrice
    ? ((quoteAmountUsd - baseTokenUsdPrice) / baseTokenUsdPrice) * 100
    : 0

  const activePriceWarningMessage =
    (diffPercent >= 5 || diffPercent <= -5) && baseTokenUsdPrice && activePrice
      ? t`The active price is set at ${formattedNum(quoteAmountUsd, {
          usd: true
        })} per ${currencyX?.symbol}, but the current market price for ${currencyX?.symbol} is ${formattedNum(
          baseTokenUsdPrice,
          { usd: true }
        )}. This represents a deviation of ${diffPercent.toFixed(
          2
        )}% from the current market price. Please review the price settings to ensure accuracy.`
      : undefined

  // reset state if an input changes
  useEffect(() => {
    resetCreateLBPair()
  }, [currencyX, currencyY, selectedBinStep, activePrice, resetCreateLBPair])

  return (
    <Flex
      flexDir="column"
      pt={{ base: 6, md: '40px' }}
      maxW="1300px"
      margin="0 auto"
      minH="100vh"
      px={4}
      align="center"
      mb={{ base: 0, md: '100px' }}
    >
      <Heading size="lg" mb={8}>
        <Trans>Create New Pool</Trans>
      </Heading>
      <Flex
        bg="bgCard"
        border="1px solid"
        borderColor="border"
        w="full"
        maxW="928px"
        flexDir="column"
        gap={8}
        p={{ base: 4, md: 8 }}
        borderRadius="2xl"
      >
        <CreatePoolTokenSection
          title="Select Token"
          currency={currencyX}
          onCurrencyChange={(currency) => {
            setCurrencyX(currency)
            if (currencyY?.symbol === currency?.symbol) {
              setCurrencyY(undefined)
            }
          }}
        />
        <CreatePoolTokenSection
          title="Select Quote Asset"
          selectableCurrencies={quoteTokens.filter(
            (token) => currencyX?.symbol !== token.symbol
          )}
          currency={currencyY}
          onCurrencyChange={setCurrencyY}
        />
        <VStack align="flex-start">
          <Text>
            <Trans>Select Bin Step</Trans>
          </Text>
          <Tabs
            w="full"
            variant="solid-rounded"
            isFitted
            index={binSteps.findIndex(
              ({ binStep: step }) => step === selectedBinStep
            )}
            onChange={(index) => setBinStep(binSteps[index].binStep)}
          >
            <TabList h={{ base: 'fit-content', md: '48px' }}>
              {binSteps.map(({ baseFee, binStep, isDisabled }) => (
                <Tooltip
                  key={binStep}
                  label={t`Coming soon`}
                  isDisabled={!isDisabled}
                >
                  <Tab isDisabled={isDisabled}>
                    <VStack spacing={-1}>
                      <Text
                        color={
                          selectedBinStep === binStep ? 'white' : 'textPrimary'
                        }
                      >{`${binStep / 100}%`}</Text>
                      <Text
                        fontSize="xs"
                        textColor={
                          selectedBinStep === binStep
                            ? 'white'
                            : 'textSecondary'
                        }
                        opacity={selectedBinStep === binStep ? 0.8 : 1}
                      >{`Base Fee: ${baseFee / 100}%`}</Text>
                    </VStack>
                  </Tab>
                </Tooltip>
              ))}
            </TabList>
          </Tabs>
        </VStack>
        <VStack align="flex-start">
          <Flex justify="space-between" w="full">
            <Text>
              <Trans>Enter Active Price</Trans>
            </Text>
            {currencyX && currencyY ? (
              <Skeleton isLoaded={!isLoadingPricesUsd && !!price}>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setActivePrice(price?.toString() || '')}
                >
                  <Text textColor="textSecondary">
                    <Trans>Current price:</Trans>{' '}
                    <Box as="span" textColor="textPrimary">
                      {`1 ${currencyX?.symbol} = ${fmtCurrentPrice} ${currencyY?.symbol}`}
                    </Box>
                  </Text>
                </Button>
              </Skeleton>
            ) : null}
          </Flex>
          <InputGroup>
            <NumericalInput
              w="full"
              size={{ base: 'md', md: 'lg' }}
              inputType="decimal"
              placeholder="0.0"
              value={activePrice}
              onValueChange={setActivePrice}
            />
            {currencyX && currencyY ? (
              <InputRightElement w="fit-content" h="full" p={4}>
                {quoteAmountUsd ? (
                  <Text textColor="textSecondary" fontSize="sm" pr={4}>
                    {`~${formattedNum(quoteAmountUsd, {
                      usd: true
                    })}`}
                  </Text>
                ) : null}
                <Text
                  textColor="textSecondary"
                  borderLeft="1px solid"
                  borderLeftColor="border"
                  pl={4}
                >{`${currencyY.symbol} per ${currencyX.symbol}`}</Text>
              </InputRightElement>
            ) : null}
          </InputGroup>
        </VStack>

        {activePriceWarningMessage ? (
          <WarningOutlined message={activePriceWarningMessage} />
        ) : null}

        {isLBPairCreated ? (
          <Button
            variant="primary"
            colorScheme="accent"
            w="full"
            size="lg"
            rightIcon={<ArrowForwardIcon boxSize="20px" />}
            onClick={() =>
              navigate(
                getPoolDetailV2Url(
                  'v22',
                  selectedBinStep,
                  chainId,
                  wrappedCurrency(currencyX, chainId)?.address,
                  wrappedCurrency(currencyY, chainId)?.address
                )
              )
            }
          >
            <Trans>View Pool</Trans>
          </Button>
        ) : (
          <Box>
            <Web3Button
              variant="primary"
              colorScheme={activePriceWarningMessage ? 'red' : 'accent'}
              w="full"
              size="lg"
              isDisabled={!createLBPair}
              isLoading={isCreatingLBPair}
              loadingText="Creating LB pair"
              onClick={createLBPair}
            >
              {activePriceWarningMessage ? (
                <Trans>Create Pool Anyway</Trans>
              ) : (
                <Trans>Create Pool</Trans>
              )}
            </Web3Button>
            {prepareConfigError ? (
              <Text mt={4} textAlign="center" color="red.500">
                <Trans>
                  Unable to prepare the transaction. Please try again later.
                </Trans>
              </Text>
            ) : null}
          </Box>
        )}
        {isPoolAlreadyExisting ? (
          <Box
            py={3}
            px={4}
            border="1px solid"
            borderColor="yellowBar"
            borderRadius={{ base: 'lg', md: 'xl' }}
          >
            <HStack spacing={3}>
              <WarningIcon boxSize={5} />
              <Box as="span">
                <Trans>
                  This pool already exists and can&#39;t be created again.{' '}
                  <Link
                    as={NavLink}
                    textDecor="underline"
                    to={getPoolDetailV2Url(
                      'v22',
                      selectedBinStep,
                      chainId,
                      wrappedCurrency(currencyX, chainId)?.address,
                      wrappedCurrency(currencyY, chainId)?.address
                    )}
                    _hover={{ opacity: 0.5 }}
                  >
                    Click here to view pool.
                  </Link>
                </Trans>
              </Box>
            </HStack>
          </Box>
        ) : null}
      </Flex>
      <Link
        mt={8}
        href="https://support.lfj.gg/en/articles/7238914-permissionless-liquidity-book-pools"
        isExternal
        _hover={{ color: 'textSecondary', textDecor: 'underline' }}
      >
        <HStack>
          <Text textColor="textSecondary">
            <Trans>Learn how to open a Liquidity Book Pool</Trans>
          </Text>
          <ExternalLinkIcon color="textSecondary" />
        </HStack>
      </Link>
    </Flex>
  )
}

export default CreatePoolV2
