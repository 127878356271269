import { Hex, zeroAddress } from 'viem'

import { MerchantMoeChainId } from './chains'

export const MOE_FACTORY_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x5bEf015CA9424A7C07B68490616a4C1F094BEdEc',
  [MerchantMoeChainId.FUJI]: '0xf311D54D81ea264d217e0e67442785D132376CE5'
}

export const MOE_ROUTER: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0xeaEE7EE68874218c3558b40063c42B82D3E7232a',
  [MerchantMoeChainId.FUJI]: '0x1d0657af320b7108919E4Ef0616EF6Cd005be1A4'
}

export const MOE_TOKEN_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x4515A45337F461A11Ff0FE8aBF3c606AE5dC00c9',
  [MerchantMoeChainId.FUJI]: '0x8764aB3d025351839c8972919E1608425D161494'
}

export const JOE_STAKING: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x79f316C45E9b62638A8304FFffA9806439b69D44',
  [MerchantMoeChainId.FUJI]: '0xb3938E6ee233E7847a5F17bb843E9bD0Aa07e116'
}

export const JOE_STAKING_REWARDER: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x681a2aAe9248821F92dD74816870b9EE40Bea0F9',
  [MerchantMoeChainId.FUJI]: '0x6c898b90A82102b0812650a803F3494fA9d623Ea'
}

export const MOE_STAKING: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0xb3938E6ee233E7847a5F17bb843E9bD0Aa07e116',
  [MerchantMoeChainId.FUJI]: '0xA756f7D419e1A5cbd656A438443011a7dE1955b5'
}

export const STABLE_MOE_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0xB5Bd280567C5A62df1A5570c88e63a5670cBA22d',
  [MerchantMoeChainId.FUJI]: '0x55160b0f39848A7B844f3a562210489dF301dee7'
}

export const VE_MOE_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x55160b0f39848A7B844f3a562210489dF301dee7',
  [MerchantMoeChainId.FUJI]: '0x79f316C45E9b62638A8304FFffA9806439b69D44'
}

export const MOE_LENS_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x527ef5b98337b532f0215bdb0da709439ad3b0f6',
  [MerchantMoeChainId.FUJI]: '0x527ef5b98337b532f0215bdb0da709439ad3b0f6'
}

export const MOE_MASTERCHEF_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0xA756f7D419e1A5cbd656A438443011a7dE1955b5',
  [MerchantMoeChainId.FUJI]: '0xE283Db759720982094de7Fc6Edc49D3adf848943'
}

export const MOE_HELPER_ADDRESS: { [chainId in MerchantMoeChainId]: Hex } = {
  [MerchantMoeChainId.MANTLE]: '0x3f0E209213d93508a451d521fD758cBc3B78cA90',
  [MerchantMoeChainId.FUJI]: '0xEFF8ee9e20ebF6B12Dc091Ae3771fcd1C5047C1C'
}

export const REWARDER_FACTORY_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0xE283Db759720982094de7Fc6Edc49D3adf848943',
  [MerchantMoeChainId.FUJI]: '0x5Ab84d68892E565a8bF077A39481D5f69edAAC02'
}

export const ETHENA_LP_STAKING_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0xf2fa332bD83149c66b09B45670bCe64746C6b439',
  [MerchantMoeChainId.FUJI]: zeroAddress
}

export const LIQUIDITY_HELPER_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0x57FF9d1a7cf23fD1A9fd9DC07823F950a22a718C',
  [MerchantMoeChainId.FUJI]: '0xb685649E08cb1b30A33708dFA8C7af41482E732E'
}

export const LB_HOOKS_LENS_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0x93185784e04D8B5a0aDe98d80C48AA06B9689Ee8',
  [MerchantMoeChainId.FUJI]: '0x93185784e04D8B5a0aDe98d80C48AA06B9689Ee8'
}

export const LB_FACTORY_V22_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0xa6630671775c4EA2743840F9A5016dCf2A104054',
  [MerchantMoeChainId.FUJI]: '0xa6630671775c4EA2743840F9A5016dCf2A104054'
}

export const LB_ROUTER_V22_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0x013e138EF6008ae5FDFDE29700e3f2Bc61d21E3a',
  [MerchantMoeChainId.FUJI]: '0xAFb85a12Babfafabfe1a518594492d5a830e782a'
}

export const LB_QUOTER_V22_ADDRESS: {
  [chainId in MerchantMoeChainId]: Hex
} = {
  [MerchantMoeChainId.MANTLE]: '0xE25Dc29b856D5A6dF45B5D854ccF9223D716f46d',
  [MerchantMoeChainId.FUJI]: '0xE25Dc29b856D5A6dF45B5D854ccF9223D716f46d'
}
