import { Currency, WNATIVE } from '@traderjoe-xyz/sdk-core'
import usePair from 'hooks/pool/v1/usePair'
import usePoolV1ByTokens, {
  fetchPoolV1ByTokens
} from 'hooks/pool/v1/usePoolV1ByTokens'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { usePublicClient } from 'wagmi'

interface UsePoolV1LiquidityPanelProps {
  token0Address?: string
  token1Address?: string
}

const usePoolV1LiquidityPanel = ({
  token0Address,
  token1Address
}: UsePoolV1LiquidityPanelProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()
  const publicClient = usePublicClient({ chainId })

  const isParam0NativeCurrency =
    nativeCurrency?.symbol.toLowerCase() === token0Address?.toLowerCase()
  const poolQueryToken0 = isParam0NativeCurrency
    ? WNATIVE[chainId].address
    : token0Address

  const isParam1NativeCurrency =
    nativeCurrency?.symbol.toLowerCase() === token1Address?.toLowerCase()
  const poolQueryToken1 = isParam1NativeCurrency
    ? WNATIVE[chainId].address
    : token1Address

  const { data: pool, isLoading: isLoadingPool } = usePoolV1ByTokens({
    tokens:
      poolQueryToken0 && poolQueryToken1
        ? [poolQueryToken0, poolQueryToken1]
        : undefined
  })

  const [_currency0, setCurrency0] = useState<Currency | undefined>()
  const [_currency1, setCurrency1] = useState<Currency | undefined>()

  const {
    tokens: [paramCurrency0, paramCurrency1]
  } = useSdkCurrencies({
    addresses: [token0Address, token1Address]
  })

  const currency0 = useMemo(
    () => _currency0 ?? paramCurrency0,
    [_currency0, paramCurrency0]
  )
  const currency1 = useMemo(
    () => _currency1 ?? paramCurrency1,
    [_currency1, paramCurrency1]
  )

  const { data: pair, isLoading: isLoadingPair } = usePair({
    pairAddress: pool?.pairAddress,
    token0Info: pool?.tokenX,
    token1Info: pool?.tokenY
  })

  const [isLoadingNewPair, setIsLoadingNewPair] = useState(false)
  const onCurrencySelect = useCallback(
    async (currency: Currency, pairedCurrency?: Currency) => {
      const tokenAddress = wrappedCurrency(currency, chainId)?.address
      const pairedTokenAddress = wrappedCurrency(pairedCurrency, chainId)
        ?.address
      if (!pairedTokenAddress || !tokenAddress) {
        return
      }
      setIsLoadingNewPair(true)
      const pool = await fetchPoolV1ByTokens({
        chainId,
        publicClient,
        tokens: [pairedTokenAddress, tokenAddress]
      })
      setIsLoadingNewPair(false)
      if (pool) {
        setCurrency0(undefined)
        setCurrency1(undefined)
        navigate(
          `/pool/v1/${currency.isNative ? currency.symbol : tokenAddress}/${
            pairedCurrency?.isNative
              ? nativeCurrency?.symbol
              : pool.token0.address.toLowerCase() === tokenAddress.toLowerCase()
                ? pool.token1.address
                : pool.token0.address
          }`
        )
      }
    },
    [navigate, chainId, nativeCurrency, publicClient]
  )

  const onToken0Select = useCallback(
    async (currency: Currency) => {
      const isEqualToCurrency1 =
        (currency.isNative && currency1?.isNative) ||
        (currency.isToken && currency1?.isToken && currency.equals(currency1))
      if (isEqualToCurrency1) {
        setCurrency0(currency)
        setCurrency1(undefined)
      } else {
        setCurrency0(currency)
        onCurrencySelect(currency, currency1)
      }
    },
    [onCurrencySelect, currency1]
  )

  const onToken1Select = useCallback(
    async (currency: Currency) => {
      const isEqualToCurrency0 =
        (currency.isNative && currency0?.isNative) ||
        (currency.isToken && currency0?.isToken && currency.equals(currency0))
      if (isEqualToCurrency0) {
        setCurrency0(undefined)
        setCurrency1(currency)
      } else {
        setCurrency1(currency)
        onCurrencySelect(currency, currency0)
      }
    },
    [onCurrencySelect, currency0]
  )

  return {
    isLoading: isLoadingPair || isLoadingPool || isLoadingNewPair,
    onToken0Select,
    onToken1Select,
    pair,
    pool,
    token0: currency0,
    token1: currency1
  }
}

export default usePoolV1LiquidityPanel
