import { FEE_RATE } from 'constants/pool'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { Pool } from 'types/pool'

import { getPoolPoints } from './points'

export const convertDexbarnPoolToPool = (dexbarnPool: DexbarnPool): Pool => {
  const fee = dexbarnPool.volumeUsd * FEE_RATE
  const apr =
    dexbarnPool.liquidityUsd > 0 ? (fee / dexbarnPool.liquidityUsd) * 365 : 0

  return {
    apr,
    farmPid: dexbarnPool.farmPid,
    feePct: 0.3,
    feesUsd: fee,
    liquidityUsd: dexbarnPool.liquidityUsd,
    name: dexbarnPool.name,
    pairAddress: dexbarnPool.pairAddress,
    points: getPoolPoints(
      dexbarnPool.tokenX.address,
      dexbarnPool.tokenY.address,
      dexbarnPool.pairAddress,
      'v1'
    ),
    tokenX: dexbarnPool.tokenX,
    tokenY: dexbarnPool.tokenY,
    volumeUsd: dexbarnPool.volumeUsd
  }
}
