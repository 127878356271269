import { Th } from '@chakra-ui/react'
import SortableTableHeader from 'components/SortableTableHeader'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import React from 'react'

interface PoolTableHeaderProps {
  isSortDescending: boolean
  name: string
  onClick: () => void
  selectedSortMethod: PoolSortMethod
  isNumeric?: boolean
  sortMethod?: PoolSortMethod
}

const PoolTableHeader = ({
  isNumeric,
  isSortDescending,
  name,
  onClick,
  selectedSortMethod,
  sortMethod
}: PoolTableHeaderProps) => {
  return sortMethod ? (
    <SortableTableHeader
      name={name}
      isSortActive={selectedSortMethod === sortMethod}
      isNumeric={isNumeric}
      isSortDescending={isSortDescending}
      onClick={onClick}
    />
  ) : (
    <Th isNumeric={isNumeric} textAlign={isNumeric ? 'right' : 'left'}>
      {name}
    </Th>
  )
}

export default PoolTableHeader
