import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import Trophy1x from 'assets/moeJourney/trophy_1x.webp'
import Trophy2x from 'assets/moeJourney/trophy_2x.webp'
import Trophy3x from 'assets/moeJourney/trophy_3x.webp'
import useMerkleProofRewards from 'hooks/moeJourney/useMerkleProofRewards'
import React, { useState } from 'react'

import { MOE_JOURNEY_REWARDER_ADDRESS } from './constants'

const MoeJourneyRewardReveal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleReveal = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const { batchClaimRewards, claimableRewards, isClaiming, isLoading } =
    useMerkleProofRewards({
      enabled: isOpen,
      market: '0x1111222233334444dEAddEAddEAdDeAdDeaDDEAd',
      rewarderAddress: MOE_JOURNEY_REWARDER_ADDRESS
    })
  const hasWon = claimableRewards && claimableRewards.length > 0
  const fmtRewards =
    claimableRewards && claimableRewards.length > 0
      ? claimableRewards
          .map((reward) => {
            return `${reward.tokenAmount} ${reward.token?.symbol}`
          })
          .join(', ')
      : '0 MNT'

  return (
    <Box w="full">
      <VStack
        p={{ base: 4, md: 6 }}
        bg="bgInteractive"
        borderRadius={{ base: '10px', md: '20px' }}
        boxShadow="element"
        spacing={8}
      >
        <Image
          fallbackSrc={Trophy2x}
          srcSet={`${Trophy1x} 1x, ${Trophy2x} 2x, ${Trophy3x} 3x`}
          maxH="300px"
        />

        <Button
          variant="primary"
          size="xl"
          colorScheme="deepRed"
          w="full"
          boxShadow="0px 2px 5px 0px color(display-p3 0 0 0 / 0.05)"
          onClick={handleReveal}
        >
          Reveal Reward Draw Results
        </Button>
      </VStack>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {!isLoading
              ? hasWon
                ? 'Congratulations Journeyman!'
                : "That's a wrap Journeyman!"
              : 'Revealing Results'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Center flexDir="column" minH="200px">
                <Spinner />
                <Text mt={4}>Fetching rewards</Text>
              </Center>
            ) : (
              <Center flexDir="column">
                {hasWon ? (
                  <Text
                    my={8}
                    fontWeight="semibold"
                    fontSize="2xl"
                  >{`You've won ${fmtRewards}`}</Text>
                ) : (
                  <Text>
                    {`Moe's Journey SZN4 has ended. There are no additional rewards for you to claim. Stay tuned for more reward opportunities in future Moe campaigns!`}
                  </Text>
                )}
              </Center>
            )}
          </ModalBody>
          <ModalFooter>
            {!isLoading && hasWon ? (
              <Button
                w="full"
                size="xl"
                variant="primary"
                colorScheme="accent"
                onClick={batchClaimRewards}
                isLoading={isClaiming}
                isDisabled={!batchClaimRewards}
              >
                Claim Rewards
              </Button>
            ) : !isLoading ? (
              <Button variant="ghost" onClick={handleClose}>
                Close
              </Button>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default MoeJourneyRewardReveal
