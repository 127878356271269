import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import { MOE_EMISSION_TO_FARM_SHARE } from 'constants/farm'
import { MOE_TOKEN_ADDRESS } from 'constants/moe'
import { MOE_TOKEN } from 'constants/tokens'
import { DexbarnFarmBribe, Farm as DexbarnFarm } from 'types/dexbarn'
import { Farm as LensFarm } from 'types/dexlens'
import { Farm } from 'types/farm'
import { formatUnits, Hex, zeroAddress } from 'viem'

import { MerchantMoeChainId } from '../constants/chains'

export const getUserStakedAmounts = (farm: LensFarm) => {
  const reserve0 = farm.reserves.reserve0
  const reserve1 = farm.reserves.reserve1

  const userReserve0 =
    farm.totalSupply > 0
      ? (farm.userAmount * reserve0) / farm.totalSupply
      : BigInt(0)
  const userReserve1 =
    farm.totalSupply > 0
      ? (farm.userAmount * reserve1) / farm.totalSupply
      : BigInt(0)

  return [
    Number(formatUnits(userReserve0, Number(farm.reserves.token0.decimals))),
    Number(formatUnits(userReserve1, Number(farm.reserves.token1.decimals)))
  ]
}

export const getUserPendingRewards = (
  farm: LensFarm,
  chainId: MerchantMoeChainId
): { amount: number; token: { address: string; symbol?: string } }[] => {
  const moeRewards = farm.userPendingMoeReward
  const moeToken = MOE_TOKEN[chainId]

  const rewards = [
    {
      amount: Number(formatUnits(moeRewards, Number(moeToken.decimals))),
      token: { address: moeToken.address, symbol: moeToken.symbol }
    }
  ]

  const rewardToken = farm.rewarder.reward.token
  const rewardAmount = farm.rewarder.reward.userPendingAmount

  if (rewardAmount > 0) {
    const rewardAmountNum = Number(
      formatUnits(rewardAmount, Number(rewardToken.decimals))
    )

    if (rewardToken.token.toLowerCase() === moeToken.address.toLowerCase()) {
      rewards[0].amount += rewardAmountNum
    } else {
      rewards.push({
        amount: rewardAmountNum,
        token: {
          address: rewardToken.token,
          symbol: rewardToken.symbol
        }
      })
    }
  }

  return rewards
}

export const getFarmPidFromDexbarnFarmId = (farmId: string): string => {
  return farmId.split('-')[1]
}

export const convertLensAndDexbarnFarm = ({
  chainId,
  dexbarnFarm,
  lensFarm,
  tokenPrices,
  totalVotes,
  totalVotes7dAgo,
  totalVotesOnActiveBribes,
  totalWeightForAllFarms,
  verifiedTokens,
  votesOnFarm7dAgo
}: {
  chainId: MerchantMoeChainId
  lensFarm: LensFarm
  dexbarnFarm?: DexbarnFarm
  tokenPrices?: Record<string, number> | undefined
  totalVotes?: bigint
  totalVotes7dAgo?: bigint
  totalVotesOnActiveBribes?: (number | undefined)[]
  totalWeightForAllFarms?: bigint
  verifiedTokens?: Hex[]
  votesOnFarm7dAgo?: bigint
}): Farm => {
  const wnativeAddress = WNATIVE[chainId].address.toLowerCase()
  const isLbRewarder = lensFarm.totalSupply === BigInt(1)

  const moeUsdPrice =
    dexbarnFarm?.moeUsdPrice ||
    tokenPrices?.[MOE_TOKEN_ADDRESS[chainId].toLowerCase()] ||
    0

  const moePerSecEmittedToFarm =
    Number(formatUnits(lensFarm.moePerSec, 18)) * MOE_EMISSION_TO_FARM_SHARE

  const votesPercentNow = totalVotes
    ? (Number(lensFarm.votesOnFarm) / Number(totalVotes)) * 100
    : 0
  const votesPercent7dAgo = totalVotes7dAgo
    ? (Number(votesOnFarm7dAgo) / Number(totalVotes7dAgo)) * 100
    : 0
  const votes7dChange = votesPercentNow - votesPercent7dAgo

  if (!dexbarnFarm) {
    return {
      ...lensFarm,
      activeBribes: [],
      farmApr: 0,
      isLbRewarder,
      liquidityUsd: '0',
      moePerSecEmittedToFarm,
      totalWeightOnFarmPercent: 0,
      votes7dChange,
      votesPercent: votesPercentNow
    }
  }

  const liquidityUsd = dexbarnFarm.depositedBalanceUsd

  const nbSecondsInYear = 31_536_000
  const moePerSecUsd = moePerSecEmittedToFarm * moeUsdPrice
  const moePerYearUsd = moePerSecUsd * nbSecondsInYear

  const rewardsPerYear =
    lensFarm.rewarder.rewardPerSec > 0 &&
    lensFarm.rewarder.isStarted &&
    !lensFarm.rewarder.isEnded
      ? Number(
          formatUnits(
            lensFarm.rewarder.rewardPerSec,
            Number(lensFarm.rewarder.reward.token.decimals)
          )
        ) * nbSecondsInYear
      : 0
  const rewardTokenAddress = lensFarm.rewarder.reward.token.token.toLowerCase()
  const rewardTokenUsdPrice =
    tokenPrices?.[
      rewardTokenAddress === zeroAddress
        ? WNATIVE[chainId].address.toLowerCase()
        : rewardTokenAddress
    ] || 0
  const rewardsPerYearUsd = rewardsPerYear * rewardTokenUsdPrice

  const farmApr =
    Number(liquidityUsd) > 0
      ? ((moePerYearUsd + rewardsPerYearUsd) / Number(liquidityUsd)) * 100
      : 0

  const activeBribes = dexbarnFarm.bribes.filter((bribe) =>
    isBribeActive(bribe, verifiedTokens || [])
  )

  const totalWeightOnFarmPercent =
    Number(totalWeightForAllFarms) > 0
      ? (Number(lensFarm.totalWeightOnFarm) / Number(totalWeightForAllFarms)) *
        100
      : 0

  return {
    ...lensFarm,
    activeBribes: activeBribes.map((bribe, i) => {
      const priceKey =
        bribe.tokenId === zeroAddress ? wnativeAddress : bribe.tokenId

      const rewardsPerDay =
        Number(
          formatUnits(BigInt(bribe.rewardsPerSecond), bribe.tokenDecimals)
        ) *
        60 *
        60 *
        24

      const totalVotesForBribe = totalVotesOnActiveBribes?.[i]
      const rewardsPer1mVotesPerDay = totalVotesForBribe
        ? (rewardsPerDay / totalVotesForBribe) * 1_000_000
        : 0

      return {
        bribeId: bribe.bribeId,
        farmId: bribe.farmId,
        rewardsPer1mVotesPerDay,
        rewardsPerDay,
        tokenSymbol: bribe.tokenSymbol,
        tokenUsdPrice: tokenPrices?.[priceKey]
      }
    }),
    farmApr,
    isLbRewarder,
    liquidityUsd,
    moePerSecEmittedToFarm,
    moePerSecEmittedToFarmUsd: moePerSecUsd,
    totalWeightOnFarmPercent,
    votes7dChange,
    votesPercent: votesPercentNow
  }
}

export const isBribeActive = (
  bribe: DexbarnFarmBribe,
  verifiedTokens: Hex[]
): boolean => {
  const now = Date.now() / 1000
  const lowercasedVerifiedTokens = verifiedTokens?.map((token) =>
    token.toLowerCase()
  )
  return (
    Number(bribe.rewardsPerSecond) > 0 &&
    bribe.endTimestamp > now &&
    bribe.startTimestamp <= now &&
    (lowercasedVerifiedTokens?.includes(bribe.tokenId.toLowerCase()) ||
      bribe.tokenId === zeroAddress)
  )
}

export const getBestBribe = (farm: Farm) => {
  if (farm.activeBribes.length === 0) {
    return undefined
  }

  let bestBribe = farm.activeBribes[0]
  for (let i = 1; i < farm.activeBribes.length; i++) {
    if (
      farm.activeBribes[i].rewardsPerDay *
        (farm.activeBribes[i].tokenUsdPrice || 0) >
      bestBribe.rewardsPerDay * (bestBribe.tokenUsdPrice || 0)
    ) {
      bestBribe = farm.activeBribes[i]
    }
  }

  if (bestBribe.tokenUsdPrice === undefined) {
    return undefined
  }

  return bestBribe
}
