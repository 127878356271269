import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, Link, Text } from '@chakra-ui/react'
import { chain } from 'constants/chains'
import React from 'react'
import { shortenAddress } from 'utils/addresses'

interface Props {
  address: string | undefined
  color?: string
  showAddress?: boolean
}

const ContractLink = ({
  address,
  color = 'textSecondary',
  showAddress = true
}: Props): JSX.Element => {
  const { blockExplorers } = chain

  return (
    <Link
      aria-label={`Link to contract ${address}`}
      isExternal
      href={`${blockExplorers?.default.url}address/${address}`}
      color={color}
      _hover={{ opacity: 0.5 }}
    >
      <HStack spacing={1} align="center">
        {showAddress && (
          <Text fontSize="sm" textColor={color}>
            {shortenAddress(address)}
          </Text>
        )}
        <ExternalLinkIcon boxSize="14px" />
      </HStack>
    </Link>
  )
}

export default ContractLink
