import { HStack, LinkBox, LinkOverlay, Td, Text, Tr } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Farm } from 'types/farm'
import { getUserStakedAmounts } from 'utils/farm'
import { formattedNum } from 'utils/format'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

interface FarmTableRowProps {
  farm: Farm
}

const FarmTableRow = ({ farm }: FarmTableRowProps) => {
  const chainId = useChainId()

  const fmtLiquidityUsd = formattedNum(farm.liquidityUsd, { usd: true })

  const fmtFarmApr = `${formattedNum(farm.farmApr, { places: 2 })}%`

  const fmtMoeRewardsPerDay = formattedNum(
    farm.isRewardable ? farm.moePerSecEmittedToFarm * 24 * 60 * 60 : 0
  )

  const fmtExtraRewardsPerDay =
    farm.rewarder.rewardPerSec > 0 &&
    farm.rewarder.isStarted &&
    !farm.rewarder.isEnded
      ? formattedNum(
          formatUnits(farm.rewarder.rewardPerSec * BigInt(24 * 60 * 60), 18)
        )
      : undefined

  const fmtRewards = fmtExtraRewardsPerDay
    ? t`${fmtMoeRewardsPerDay} MOE / day + ${fmtExtraRewardsPerDay} ${farm.rewarder.reward.token.symbol} / day`
    : t`${fmtMoeRewardsPerDay} MOE / day`

  const userStakedAmounts =
    farm.userAmount > 0 ? getUserStakedAmounts(farm) : undefined

  const fmtUserStakedAmounts = userStakedAmounts
    ? `${formattedNum(userStakedAmounts[0])} ${
        farm.reserves.token0.symbol
      } / ${formattedNum(userStakedAmounts[1])} ${farm.reserves.token1.symbol}`
    : '--'

  const fmtUserPendingMoeRewards =
    farm.userPendingMoeReward > 0
      ? formattedNum(formatUnits(farm.userPendingMoeReward, 18))
      : undefined

  const fmtUserPendingExtraRewards =
    farm.rewarder.reward.userPendingAmount > 0
      ? formattedNum(
          formatUnits(
            farm.rewarder.reward.userPendingAmount,
            Number(farm.rewarder.reward.token.decimals)
          )
        )
      : undefined

  const fmtUserRewards =
    fmtUserPendingMoeRewards && fmtUserPendingExtraRewards
      ? t`${fmtUserPendingMoeRewards} MOE + ${fmtUserPendingExtraRewards} ${farm.rewarder.reward.token.symbol}`
      : fmtUserPendingMoeRewards
        ? t`${fmtUserPendingMoeRewards} MOE`
        : fmtUserPendingExtraRewards
          ? t`${fmtUserPendingExtraRewards} ${farm.rewarder.reward.token.symbol}`
          : '--'

  const nativeCurrency = CNATIVE.onChain(chainId)
  const currencyX = isWrappedNativeCurrency(farm.reserves.token0.token, chainId)
    ? nativeCurrency
    : farm.reserves.token0
  const currencyY = isWrappedNativeCurrency(farm.reserves.token1.token, chainId)
    ? nativeCurrency
    : farm.reserves.token1

  return (
    <LinkBox
      data-cy={`farm-row-${farm.pid}`}
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      boxShadow="element"
      borderRadius="10px"
    >
      <Td borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
        <LinkOverlay
          aria-label={`Link to farm page for ${farm.reserves.token0.symbol} / ${farm.reserves.token1.symbol}`}
          href={`/farms/${farm.pid}`}
        />
        <HStack>
          <DoubleCurrencyLogo
            address0={
              currencyX instanceof CNATIVE ? undefined : currencyX.token
            }
            address1={
              currencyY instanceof CNATIVE ? undefined : currencyY.token
            }
            symbol0={currencyX.symbol}
            symbol1={currencyY.symbol}
            boxSize={6}
          />
          <Text>{`${currencyX.symbol} / ${currencyY.symbol}`}</Text>
        </HStack>
      </Td>
      <Td>
        <Trans>{fmtLiquidityUsd}</Trans>
      </Td>
      <Td>
        <Trans>{fmtFarmApr}</Trans>
      </Td>
      <Td>
        <Trans>{fmtRewards}</Trans>
      </Td>
      <Td>
        <Trans>{fmtUserRewards}</Trans>
      </Td>
      <Td borderTopRightRadius="10px" borderBottomRightRadius="10px">
        {fmtUserStakedAmounts}
      </Td>
    </LinkBox>
  )
}

export default FarmTableRow
