import { useQuery } from '@tanstack/react-query'
import { MerchantMoeChainId } from 'constants/chains'
import { MOE_FACTORY_ADDRESS } from 'constants/moe'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV1'
import { erc20Abi, getAddress, parseAbi, zeroAddress } from 'viem'
import { usePublicClient } from 'wagmi'

dayjs.extend(utc)

interface FetchPoolV1ByTokensProps {
  chainId: MerchantMoeChainId
  publicClient: ReturnType<typeof usePublicClient>
  tokens?: [string, string]
}

export const fetchPoolV1ByTokens = async ({
  chainId,
  publicClient,
  tokens
}: FetchPoolV1ByTokensProps) => {
  if (!tokens || !publicClient) {
    return undefined
  }

  const pairAddress = await publicClient.readContract({
    abi: parseAbi([
      'function getPair(address tokenA, address tokenB) external view returns (address pair)'
    ]),
    address: MOE_FACTORY_ADDRESS[chainId],
    args: [getAddress(tokens[0]), getAddress(tokens[1])],
    functionName: 'getPair'
  })

  if (pairAddress === zeroAddress) {
    return undefined
  }

  const tokensInfo = await Promise.all([
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[0]),
      functionName: 'name'
    }),
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[0]),
      functionName: 'symbol'
    }),
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[0]),
      functionName: 'decimals'
    }),
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[1]),
      functionName: 'name'
    }),
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[1]),
      functionName: 'symbol'
    }),
    publicClient.readContract({
      abi: erc20Abi,
      address: getAddress(tokens[1]),
      functionName: 'decimals'
    })
  ])

  return {
    pairAddress,
    token0: {
      address: getAddress(tokens[0]),
      decimals: tokensInfo[2],
      name: tokensInfo[0],
      symbol: tokensInfo[1]
    },
    token1: {
      address: getAddress(tokens[1]),
      decimals: tokensInfo[5],
      name: tokensInfo[3],
      symbol: tokensInfo[4]
    }
  }
}

interface UsePoolV1ByTokensProps {
  tokens?: [string, string]
}

const usePoolV1ByTokens = ({ tokens }: UsePoolV1ByTokensProps) => {
  const chainId = useChainId()
  const publicClient = usePublicClient({ chainId })
  const result = useQuery({
    enabled: !!tokens,
    queryFn: () => fetchPoolV1ByTokens({ chainId, publicClient, tokens }),
    queryKey: ['PoolV1ByTokensQuery', tokens]
  })

  const pairAddress = result.data?.pairAddress
  const fetchPool = useDexbarnGet<DexbarnPool>(
    `v1/pools/${getDexbarnChainParam(chainId)}/${pairAddress}`
  )
  return useQuery({
    enabled: !!pairAddress,
    queryFn: () => fetchPool(),
    queryKey: ['PoolV1', chainId, pairAddress],
    select: convertDexbarnPoolToPool
  })
}

export default usePoolV1ByTokens
