import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  Grid,
  Hide,
  HStack,
  IconButton,
  Link,
  Text,
  useClipboard,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import usePoolV2 from 'hooks/pool/v2/usePoolV2'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { CopyIcon, LinkIcon } from 'theme/icons'
import { shortenAddress } from 'utils/addresses'

interface PoolInfoTableValueProps {
  label: string
  isValueCopyable?: boolean
  link?: string
  tooltipLabel?: string
  value?: string
}

const PoolInfoTableValue = ({
  isValueCopyable,
  label,
  link,
  tooltipLabel,
  value
}: PoolInfoTableValueProps) => {
  const { onCopy } = useClipboard(value || '')

  return (
    <VStack spacing={0} align="flex-start">
      <TouchFriendlyTooltip label={tooltipLabel} isDisabled={!tooltipLabel}>
        <HStack spacing={1}>
          <Text fontSize="sm" color="textSecondary" fontWeight="semibold">
            {label}
          </Text>
          {tooltipLabel ? (
            <InfoOutlineIcon color="textSecondary" boxSize="14px" />
          ) : null}
        </HStack>
      </TouchFriendlyTooltip>
      <HStack align="center" spacing={1}>
        <Text fontWeight="semibold">{value ?? '--'}</Text>
        {isValueCopyable && value ? (
          <Hide below="sm">
            <IconButton
              aria-label={`copy ${label}`}
              variant="ghost"
              _hover={{ opacity: 0.5 }}
              size="xs"
              icon={<CopyIcon fill="textSecondary" boxSize="17px" />}
              onClick={onCopy}
            />
          </Hide>
        ) : null}
        {link ? (
          <Link isExternal href={link} _hover={{ opacity: 0.5 }}>
            <Center>
              <LinkIcon fill="textSecondary" boxSize="20px" />
            </Center>
          </Link>
        ) : null}
      </HStack>
    </VStack>
  )
}

interface PoolInfoTableProps {
  lbPairAddress?: string
}

const PoolInfoTable = ({ lbPairAddress }: PoolInfoTableProps) => {
  const [isLargerThanLg] = useMediaQuery('(min-width: 992px)')
  const { blockExplorers } = useActiveChain()
  const { data: pool } = usePoolV2({ address: lbPairAddress })

  if (!pool) return null

  return (
    <Grid
      columnGap={{ base: 1, sm: 4 }}
      templateColumns={{
        base: 'minmax(0, 1fr) 80px 80px',
        sm: 'minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)'
      }}
    >
      <Flex flexDir="column" gap={4}>
        <PoolInfoTableValue
          label="Pool"
          value={
            isLargerThanLg ? pool.pairAddress : shortenAddress(pool.pairAddress)
          }
          link={`${blockExplorers?.default.url}address/${pool.pairAddress}`}
          isValueCopyable
        />
        <PoolInfoTableValue
          label={pool.tokenX.symbol}
          value={
            isLargerThanLg
              ? pool.tokenX.address
              : shortenAddress(pool.tokenX.address)
          }
          link={`${blockExplorers?.default.url}address/${pool.tokenX.address}`}
          isValueCopyable
        />
        <PoolInfoTableValue
          label={pool.tokenY.symbol}
          value={
            isLargerThanLg
              ? pool.tokenY.address
              : shortenAddress(pool.tokenY.address)
          }
          link={`${blockExplorers?.default.url}address/${pool.tokenY.address}`}
          isValueCopyable
        />
      </Flex>
      <Flex flexDir="column" gap={4}>
        <PoolInfoTableValue label="Bin steps" value={`${pool.lbBinStep}bps`} />
        <PoolInfoTableValue
          label={isLargerThanLg ? 'Pool version' : 'Version'}
          value={pool.lbPoolVersion === 'v22' ? 'v2.2' : pool.lbPoolVersion}
        />
      </Flex>
      <Flex flexDir="column" gap={4}>
        <PoolInfoTableValue
          label="Base fee"
          value={pool.feePct.toString() + '%'}
        />
        <PoolInfoTableValue
          label="Max fee"
          value={pool.lbMaxFeePct.toString() + '%'}
        />
        <PoolInfoTableValue
          label="Protocol fee"
          value={pool.protocolFeePct.toString() + '%'}
          tooltipLabel="100% of protocol share is distributed to MOE stakers"
        />
      </Flex>
    </Grid>
  )
}

export default PoolInfoTable
