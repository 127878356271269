import { HStack, Image } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import EthenaLogo from 'assets/ethena.png'
import FBTCLogo from 'assets/fbtc.svg'
import PowderLogo from 'assets/powder.png'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'
import { Point } from 'types/pool'

interface PointsBadgeProps {
  points: Point[]
  boxSize?: number
}

const PointsBadge = ({ boxSize = 6, points }: PointsBadgeProps) => {
  const elements: JSX.Element[] = []

  if (points.includes('powder_20x_s2_stable')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`Liquidity Providers will accrue 20x Powder per day for liquidity within 0.1% of the active price as a part of Mantle's Methamorphosis SZN2 campaign.`}
      >
        <Image src={PowderLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('powder_30x_s2_stable')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`Liquidity Providers will accrue 30x Powder per day for liquidity within 3% of the active price as a part of Mantle's Methamorphosis SZN2 campaign.`}
      >
        <Image src={PowderLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('powder_30x_s2_volatile')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`Liquidity Providers will accrue 30x Powder per day for liquidity within 20% of the active price as a part of Mantle's Methamorphosis SZN2 campaign.`}
      >
        <Image src={PowderLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('powder_40x_s2_volatile')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`Liquidity Providers will accrue 40x Powder per day for liquidity within 20% of the active price as a part of Mantle's Methamorphosis SZN2 campaign.`}
      >
        <Image src={PowderLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('ethena')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`30x SATS points will be distributed to Liquidity Providers in this Pool.`}
      >
        <Image src={EthenaLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('ethena_30x_volatile')) {
    elements.push(
      <TouchFriendlyTooltip
        label={t`Liquidity Providers will accrue 30x Sats for liquidity within ±20% of active price as a part of Ethena's Sats campaign.`}
      >
        <Image src={EthenaLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('fbtc_2x')) {
    elements.push(
      <TouchFriendlyTooltip
        label={`Liquidity Providers will accrue 2x Sparks per 0.001 FBTC / day as a part of the Ignition Sparle campaign.`}
      >
        <Image src={FBTCLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  if (points.includes('fbtc_4x')) {
    elements.push(
      <TouchFriendlyTooltip
        label={`Liquidity Providers will accrue 4x Sparks per 0.001 FBTC / day as a part of the Ignition Sparle campaign.`}
      >
        <Image src={FBTCLogo} boxSize={boxSize} />
      </TouchFriendlyTooltip>
    )
  }

  return <HStack>{elements}</HStack>
}

export default PointsBadge
