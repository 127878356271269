import { Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useDebounce from 'hooks/useDebounce'
import { RouterType, TradeBestPath } from 'types/trade'

import useGetBestPathFromBarn from './useGetBestPathFromBarn'
import useGetOnChainBestTrade from './useGetOnChainBestTrade'

interface UseGetBestTradeProps {
  isExactIn: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  refetchInterval?: number
  typedTokenAmount?: TokenAmount
}

const useGetBestTrade = ({
  inputCurrency,
  isExactIn,
  outputCurrency,
  refetchInterval,
  typedTokenAmount
}: UseGetBestTradeProps): {
  isFetching: boolean
  lastFetchTime: number
  refetch: () => void
  data?: TradeBestPath
  routerType?: RouterType
} => {
  const debouncedAmount = useDebounce(typedTokenAmount, 250)

  const barnResult = useGetBestPathFromBarn({
    amount: debouncedAmount?.raw?.toString(),
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    isExactIn,
    refetchInterval
  })

  const onChainResult = useGetOnChainBestTrade({
    inputCurrency,
    isExactIn,
    outputCurrency,
    refetchInterval,
    typedTokenAmount: debouncedAmount
  })

  const refetch = () => {
    barnResult.refetch()
    onChainResult.refetch()
  }

  if (barnResult.isFetching || onChainResult.isFetching) {
    return {
      isFetching: true,
      lastFetchTime: barnResult.lastFetchTime,
      refetch
    }
  }

  if (barnResult.data && !onChainResult.data) {
    return {
      data: barnResult.data,
      isFetching: false,
      lastFetchTime: barnResult.lastFetchTime,
      refetch,
      routerType: 'barn'
    }
  }

  if (!barnResult.data && onChainResult.data) {
    return {
      data: onChainResult.data,
      isFetching: false,
      lastFetchTime: barnResult.lastFetchTime,
      refetch,
      routerType: 'frontend'
    }
  }

  if (!barnResult.data || !onChainResult.data) {
    return {
      isFetching: false,
      lastFetchTime: barnResult.lastFetchTime,
      refetch
    }
  }

  const tradeBestPath =
    barnResult.data.amountOut.value >= onChainResult.data.amountOut.value
      ? barnResult.data
      : onChainResult.data

  const routerType: RouterType =
    barnResult.data.amountOut.value === onChainResult.data.amountOut.value
      ? 'aligned'
      : barnResult.data.amountOut.value > onChainResult.data.amountOut.value
        ? 'barn'
        : 'frontend'
  return {
    data: tradeBestPath,
    isFetching: false,
    lastFetchTime: barnResult.lastFetchTime,
    refetch,
    routerType
  }
}

export default useGetBestTrade
